<template>
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <div class="card-header cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Professional</h3>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          @click="saveData"
          class="btn btn-sm btn-success align-self-center"
        >
          Save
        </button>
        <button
          @click="$emit('discard')"
          class="btn btn-sm btn-danger align-self-center "
        >
          Discard
        </button>
      </div>
    </div>
    <div class="card-body p-9">
      <div class="row mb-3 align-items-center">
        <label class="col-lg-4 fw-bold text-muted">Primary Branch</label>
        <div class="col-lg-5">
          <BranchesDropdown v-model="data.primary_branch_id"></BranchesDropdown>
        </div>
        <div class="col-lg-3">
          <div class="form-check ms-4 mt-4">
            <input
              class="form-check-input"
              type="checkbox"
              id="invoiceCheckbox"
              v-model="data.issues_invoice"
            />
            <label class="form-check-label fs-5" for="invoiceCheckbox"
              >Issues invoice ?</label
            >
          </div>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <label class="col-lg-4 fw-bold text-muted">Other branches</label>
        <div class="col-lg-5">
          <BranchesDropdownMultiple
            v-model="data.branches"
          ></BranchesDropdownMultiple>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <label class="col-lg-4 fw-bold text-muted">Shoe size</label>
        <div class="col-lg-5">
          <InputField
            v-model="data.shoe_size"
            :placeholder="'Enter shoe size'"
          ></InputField>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <label class="col-lg-4 fw-bold text-muted">Pants size</label>
        <div class="col-lg-5">
          <InputField
            v-model="data.pants_size"
            :placeholder="'Enter pants size'"
          ></InputField>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <label class="col-lg-4 fw-bold text-muted">Shirt size</label>
        <div class="col-lg-5">
          <InputField
            v-model="data.shirt_size"
            :placeholder="'Enter shirt size'"
          ></InputField>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <label class="col-lg-4 fw-bold text-muted">About me</label>
        <div class="col-lg-5">
          <el-input
            v-model="data.about_me"
            :rows="2"
            type="textarea"
            placeholder="Tell us more about you"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import BranchesDropdown from "@/components/dropdown/BranchesDropdown.vue";
import BranchesDropdownMultiple from "@/components/dropdown/BranchesDropdownMultiple.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapGetters, mapActions } from "vuex";
import InputField from "@/components/general/InputField.vue"

export default defineComponent({
  name: "user-personal-edit",
  components: {
    InputField,
    BranchesDropdown,
    BranchesDropdownMultiple
  },
  data() {
    return {
      data: {
        shoe_size: "",
        pants_size: "",
        shirt_size: "",
        primary_branch_id: "",
        branches: "",
        issues_invoice: "",
        about_me: ""
      }
    };
  },
  mounted() {
    this.data.shoe_size = this.getSingleUser.shoe_size;
    this.data.pants_size = this.getSingleUser.pants_size;
    this.data.shirt_size = this.getSingleUser.shirt_size;
    this.data.issues_invoice = !!this.getSingleUser.issues_invoice; //True value comes as 1 from the DB
    this.data.primary_branch_id = this.getSingleUser.branches.filter(branch => branch.pivot.is_primary == 1).map(branch => branch.id)[0];
    this.data.branches = this.getSingleUser.branches.filter(branch => branch.pivot.is_primary == 0).map(branch => branch.id);
    this.data.about_me = this.getSingleUser.about_me ? this.getSingleUser.about_me : "";
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"])
  },
  methods: {
    ...mapActions("UsersModule", ["update"]),
    saveData() {
      const payload = {
        ...this.data,
        first_name : this.getSingleUser.first_name,
        languages : this.getSingleUser.languages.map(language => language.id),
        role : this.getSingleUser.role.map(role => role.id),
        photo : this.getSingleUser.photo
      }
      this.update({
          id: this.$route.params.id,
          payload: payload
      })
      Swal.fire({
        text: "The record has been successfully updated !",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      }).then(() => {
        this.$emit("saved")
      });
    }
  },
});
</script>
