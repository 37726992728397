<template>
  <el-input type="text" v-model="userInput" :placeholder="placeholder" />
</template>
<script>
import { defineComponent } from "vue";
//import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "InputField",
  props: {
    placeholder: String
  },
  data() {
    return {
      userInput: ""
    };
  }
});
</script>
