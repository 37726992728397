<template>
  <EditWorkRelated
    v-if="isEdited"
    @discard="isEdited = false"
  ></EditWorkRelated>
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view" v-else>
    <div class="card-header cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Professional</h3>
      </div>
      <button
        @click="isEdited = true"
        class="btn btn-sm btn-primary align-self-center"
        v-if="
          $can('edit_user_profile_info', 'all') ||
          canEditProfile ||
            currentUser.id == $route.params.id
        "
      >
        Edit
      </button>
    </div>
    <div class="card-body p-9">
      <div class="row mb-7" v-if="getPrimaryBranch">
        <label class="col-lg-4 fw-bold text-muted">Primary branch</label>
        <div class="col-lg-8">
          <span class="fw-bold fs-6 badge rounded-pill bg-primary mx-1">{{
            getPrimaryBranch.name
          }}</span>
        </div>
      </div>

      <div class="row mb-7" v-if="otherBranches && otherBranches.length > 0">
        <label class="col-lg-4 fw-bold text-muted">Other branches</label>
        <div class="col-lg-8 fv-row">
          <span
            class="fw-bold fs-6 badge rounded-pill bg-primary mx-1"
            v-for="entry in otherBranches"
            :key="entry.id"
          >
            {{ entry.name }}
          </span>
        </div>
      </div>
      <div class="row mb-7">
        <label class="col-lg-4 fw-bold text-muted">Issues invoice ?</label>
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{
            getSingleUser.issues_invoice ? "Yes" : "No"
          }}</span>
        </div>
      </div>
      <div class="row mb-7" v-if="getSingleUser.shoe_size">
        <label class="col-lg-4 fw-bold text-muted">Shoe size</label>
        <div class="col-lg-8">
          <span class="fw-bold fs-6 text-dark"
            >{{ getSingleUser.shoe_size }}
          </span>
        </div>
      </div>

      <div class="row mb-7" v-if="getSingleUser.pants_size">
        <label class="col-lg-4 fw-bold text-muted">
          Pants size
        </label>
        <div class="col-lg-8">
          <span class="fw-bold fs-6 text-dark"
            >{{ getSingleUser.pants_size }}
          </span>
        </div>
      </div>
      <div class="row mb-9" v-if="getSingleUser.shirt_size">
        <label class="col-lg-4 fw-bold text-muted">Shirt size</label>
        <div class="col-lg-8">
          <span class="fw-bold fs-6 text-dark"
            >{{ getSingleUser.shirt_size }}
          </span>
        </div>
      </div>
      <div class="row mb-9" v-if="getSingleUser.about_me">
        <label class="col-lg-4 fw-bold text-muted">About me</label>
        <div class="col-6">
          <span class="fw-bold fs-6 text-dark"
            >{{ getSingleUser.about_me }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditWorkRelated from "@/components/users/userProfile/fields/EditWorkRelated.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "user-work",
  components: {
    EditWorkRelated
  },
  data() {
    return{
      isEdited: false
    }
  },
  mounted() {
    setCurrentPageBreadcrumbs("Professional", ["User"]);
  },
  methods: {
    canEditProfile() {
      let isWorker = false;
      if (this.getSingleUser && this.getSingleUser.role) {
        const roles = this.getSingleUser.role.map(role => role.title);
        isWorker = roles.includes("Worker");
      }
      return this.$can("can_edit_worker_profiles", "all") && isWorker;
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"]),
    ...mapGetters({ currentUser: "currentUser" }),
    getPrimaryBranch() {
      if(this.getSingleUser.branches) return this.getSingleUser.branches.find(x => x.pivot.is_primary === 1)
      else return false;
    },
    otherBranches() {
      if(this.getSingleUser.branches) return this.getSingleUser.branches.filter(x => x.pivot.is_primary == 0);
      else return false;
    }
}});
</script>
